#main-container {
  max-width: 875px;
  padding: 0;
  background-image: url('https://aodiablo.s3.us-west-2.amazonaws.com/background.jpg');
}

.sub-container {
  margin: 0 -20px;
  padding: 24px;
  width: 100%;
}

.bordered-container-bottom {
  border-bottom: 20px solid transparent;
  border-image: url('https://aodiablo.s3.us-west-2.amazonaws.com/border_fixed.jpg') 0 136 52 136 round;
}

.bordered-container-vertical {
  border-left: 20px solid transparent;
  border-right: 20px solid transparent;
  border-image: url('https://aodiablo.s3.us-west-2.amazonaws.com/border_fixed.jpg') 486 52 842 52 round;
}
